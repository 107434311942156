import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import containerStyles from "./index.module.css"

const Header = ({ siteTitle }) => (
  <header className={containerStyles.header}>
    <div className={containerStyles.headerContent}>
      <h1>
        <Link to="/" className={containerStyles.homeLink}>
          {siteTitle}
        </Link>
      </h1>
      <nav>
        <ul>
          <li>
            <Link to="/">New Campaign</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/contact">Contact Us</Link>
          </li>
        </ul>
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
